<script>
import Layout from "@/router/layouts/contracts";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      contract: null
    };
  },
  methods: {
    getContract() {
      api
        .get('contracts/' + this.$route.params.id)
        .then((response) => {
          if (response.data.status == 'success') {
            this.contract = response.data.contract
          }
        })
    },
    signContract() {
      api
        .post('contracts', {
          id: this.$route.params.id
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.$router.push('/');
          }
        })
    }
  },
  mounted() {
    this.getContract()
  }
};
</script>

<template>
  <Layout>
    <div v-if="contract" class="row justify-content-center">
      <div class="col-lg-8 col-xl-6">
        <div v-if="contract.signature.status == 'pending'" class="px-4 py-3">
          <h5>Para continuar, leia e aceite o contrato "{{ contract.title }}"</h5>
          <div v-if="contract.description" v-html="contract.description"></div>
        </div>
        <div class="card">
          <div class="card-body p-4">
            <div v-html="contract.body" class="m-1"></div>
          </div>
        </div>
        <div v-if="contract.signature.status == 'pending'" class="mb-5 text-center">
          <button class="btn btn-default btn-lg" v-on:click="signContract()">Aceitar e continuar</button>
        </div>
      </div>
    </div>
  </Layout>
</template>
